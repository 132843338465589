import React from "react"
import "./menu.css"

export default class Menu extends React.Component {
  menuClicked = (updateState) => {
    updateState()
  }

  render() {
    return (
      <div
        className={this.props.menuIsOpen ? "menu menu--open" : "menu"}
        onClick={() =>
          this.menuClicked(this.props.updateState)
        }
      >
        <div className="menu-line menu-line--one"></div>
        <div className="menu-line menu-line--two"></div>
        <div className="menu-line menu-line--three"></div>
      </div>
    )
  }
}
