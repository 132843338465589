import React from "react"
import "./mobile-navigation.css"
import FontAwesome from "react-fontawesome";
import { Link } from "gatsby"

export default class MobileNavigation extends React.Component {
  getClass(menuIsOpen) {
    if (menuIsOpen) {
      return "mobile-navigation mobile-navigation--open"
    } else {
      return "mobile-navigation"
    }
  }
  render() {
    return (
      <nav className={this.getClass(this.props.menuIsOpen)}>
        <ul className="mobile-navigation__items">
          <li>
            <Link to="/tech-check" onClick={this.props.updateState}><FontAwesome className="fas fa-check" name="" style={{marginRight: '10px', opacity: '1'}}/>Tech-check</Link>
          </li>
          <li>
            <Link to="/value-check" onClick={this.props.updateState}><FontAwesome className="fas fa-check" name="" style={{marginRight: '10px', opacity: '1'}}/>Value-check</Link>
          </li>
          <li>
            <Link to="/full-check" onClick={this.props.updateState}><FontAwesome className="fas fa-check" name="" style={{marginRight: '10px', opacity: '1'}}/>Ready-to-buy-check</Link>
          </li>
          <li>
            <Link to="/contact" onClick={this.props.updateState}><FontAwesome className="fas fa-paper-plane" name="" style={{marginRight: '10px', opacity: '1'}}/>Contact</Link>
          </li>
        </ul>
      </nav>
    )
  }
}
