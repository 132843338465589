import React from "react"
import "./navigation.css"
import Logo from "../../../static/media/logo-black.png"
import DesktopNavigation from "../desktop-navigation/desktop-navigation"
import Menu from "../menu/menu"
import MobileNavigation from "../mobile-navigation/mobile-navigation"
import Link from "gatsby-link"

export default class Navigation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      notificationElement: null,
      headerElement: null,
      navigationFixedClass: "",
      navigationBackgroundClass: "",
      navigationToRender: null,
      menuIsOpen: false,
    }
  }

  componentDidMount() {
    let notificationElements = document.getElementsByClassName("notification");
    let headerElements = document.getElementsByClassName("header");
    let headerSmallElements = document.getElementsByClassName("header-small");
    let headerElement = headerElements.length > 0 ? headerElements[0] : headerSmallElements[0];

    this.setState({
      notificationElement:
        notificationElements != null ? notificationElements[0] : null,
      headerElement: headerElement
    })
    document.addEventListener("scroll", this.trackScrolling)
    window.addEventListener("resize", this.handleResize)
    this.handleResize()
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.trackScrolling)
    window.removeEventListener("resize", this.handleResize)
  }

  updateState = () => {
    this.setState({ menuIsOpen: !this.state.menuIsOpen }, () => {
      this.setNavigationToRender(
        <Menu
          menuIsOpen={this.state.menuIsOpen}
          updateState={this.updateState}
        ></Menu>
      )
    })
  }

  setNavigationToRender(navigationToRender) {
    this.setState({
      navigationToRender: navigationToRender,
    })
  }

  handleResize = () => {
    if (window.innerWidth <= 700) {
      this.setNavigationToRender(
        <Menu
          menuIsOpen={this.state.menuIsOpen}
          updateState={this.updateState}
        ></Menu>
      )
    } else {
      this.setNavigationToRender(<DesktopNavigation></DesktopNavigation>)
    }
  }

  trackScrolling = () => {
    if (this.state.headerElement != null) {
      let headerBoundingClientRect = this.state.headerElement.getBoundingClientRect()
      if (headerBoundingClientRect.height + headerBoundingClientRect.y <= 0) {
        this.setState({
          navigationBackgroundClass: " navigation--background",
        })
      } else {
        this.setState({
          navigationBackgroundClass: "",
        })
      }
    } else {
      this.setState({
        // navigationBackgroundClass: " navigation--background",
      })
    }

    if (this.state.notificationElement != null) {
      let notificationBoundingClientRect = this.state.notificationElement.getBoundingClientRect()
      if (
        notificationBoundingClientRect.height +
          notificationBoundingClientRect.y <=
        0
      ) {
        this.setState({
          navigationFixedClass: " navigation--fixed",
        })
      } else {
        this.setState({
          navigationFixedClass: "",
        })
      }
    } else {
      this.setState({
        navigationFixedClass: " navigation--fixed",
      })
    }
  }

  render() {
    return (
      <nav
        className={
          "navigation" +
          this.state.navigationFixedClass +
          this.state.navigationBackgroundClass
        }
      >
        <div className="navigation__home">
          <Link to="/">
            <img src={Logo} alt="" />
          </Link>
        </div>
        {this.state.navigationToRender}
        <MobileNavigation
          menuIsOpen={this.state.menuIsOpen}
          updateState={this.updateState}
        ></MobileNavigation>
      </nav>
    )
  }
}
