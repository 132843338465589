import React from "react"
import "./contact-button.css"
import Link from "gatsby-link"


export default class ContactButton extends React.Component {
  render() {
    return (
      <div className="contact-button">
        <p>Meer info over onze aanpak of een offerte?</p>
        <Link to="/contact">
          <button Link="">Contacteer ons</button>
        </Link>
      </div>
    )
  }
}
