import React from "react"
import "./desktop-navigation.css"
import FontAwesome from "react-fontawesome"
import { Link } from "gatsby"

export default class DesktopNavigation extends React.Component {
  render() {
    return (
      <div className="desktop-navigation">
        <ul className="desktop-navigation__items">
          <li>
            <Link to="/tech-check"><FontAwesome className="fas fa-check" name="" style={{marginRight: '5px', opacity: '1'}}/>Tech-check</Link>
          </li>
          <li>
            <Link to="/value-check"><FontAwesome className="fas fa-check" name="" style={{marginRight: '5px', opacity: '1'}}/>Value-check</Link>
          </li>
          <li>
            <Link to="/full-check"><FontAwesome className="fas fa-check" name="" style={{marginRight: '5px', opacity: '1'}}/>Ready-to-buy-check</Link>
          </li>
          <li>
            <Link to="/contact"><FontAwesome className="fas fa-paper-plane" name="" style={{marginRight: '5px', opacity: '1'}}/>Contact</Link>
          </li>
        </ul>
      </div>
    )
  }
}
