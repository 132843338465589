import React from "react"
import "./notification.css"

export default class Notification extends React.Component {
  render() {
    return (
      <div className="notification">
        <span className="notification__title">{this.props.title}</span>
        <span className="notification__description">{this.props.description}</span>
      </div>
    )
  }
}
