import React from "react"
import "./line-animation.css"

export default class LineAnimation extends React.Component {
  constructor() {
    super()
    this.state = {
      lineClass: "line line-start",
    }
  }

  changeClass() {
    this.timerHandle = setTimeout(() => {
      this.setState({
        lineClass: "line line-end",
      })
    }, 500)
  }

  componentWillUnmount() {
    if (this.timerHandle) {
      clearTimeout(this.timerHandle)
      this.timerHandle = 0
    }
  }

  render() {
    this.changeClass()
    return (
      <div
        className={this.state.lineClass}
        style={{ backgroundColor: this.props.color, margin: this.props.margin}}
      ></div>
    )
  }
}
