import React from "react"
import "./footer.css"

export default class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        Als u deze site bezoekt, gaat u akkoord met de <a href="https://www.immophone-partners.be/nl/gdpr" target="blank" className="link">Algemene voorwaarden</a>
        {/* Vastgoed aankoopbegeleiding by <a href="http://www.immophone-partners.be/" target="blank" className="link">Immophone-partners</a> | 0477 624 790 */}
      </div>
    )
  }
}
