import React from "react"
import "./feature-list.css"
import LineAnimation from "../line-animation/line-animation"
import FontAwesome from "react-fontawesome"

export default class FeatureList extends React.Component {
  createListItems() {
    return this.props.items.map(item => (
      <li key={item.key}>
        <div>
          <div>
            <FontAwesome
              className={item.icon}
              name=""
              style={{
                marginRight: "15px",
                opacity: ".7",
                display: item.icon !== "" ? "" : "none",
              }}
            />
          </div>
          <div>{item.text || item.html}</div>
        </div>
      </li>
    ))
  }

  render() {
    return (
      <div className="feature-list">
        <h2 className="subtitle">{this.props.title}</h2>
        <LineAnimation color="#0c973a"></LineAnimation>
        <ul>{this.createListItems()}</ul>
      </div>
    )
  }
}
