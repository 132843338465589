import React from "react"
import "./header-small.css"

export default class HeaderSmall extends React.Component {
  render() {
    return (
      <header className="header-small" style={{backgroundImage: 'url(' + this.props.imageUrl + ')'}}>
        <div className="header-small__title">{this.props.title}</div>
      </header>
    )
  }
}
